// React & Next
import { createContext, useContext } from 'react';
import type { AriaAttributes, AriaRole, ReactNode, CSSProperties, DOMAttributes } from 'react';

// 3rd
import { chakra } from '@chakra-ui/react';
import type { InputDOMAttributes, InputDOMProps, PropGetter } from '@chakra-ui/utils';

// App - Types
import type { ImportanceLevel } from '@/types/security-framework/importance-level';
import type { UseRadioProps } from '@/components/molecules/form';

// App - Other
import { Text } from '@/components/atoms/typography';
import { useRadio, useRadioGroup } from '@/components/molecules/form';
import { translateImportanceLevel } from '@/components/translators/security-framework/importance-level';

const RadioContext = createContext<{
  getRadioProps: PropGetter<
    InputDOMProps &
      AriaAttributes &
      DOMAttributes<HTMLInputElement> & {
        id?: string | undefined;
        role?: AriaRole | undefined;
        tabIndex?: number | undefined;
        style?: CSSProperties | undefined;
      } & {
        isChecked?: boolean | undefined;
      },
    InputDOMAttributes
  >;
  isDisabled?: boolean;
} | null>(null);

type ControlImportanceLevelRadioProps = {
  checked: ImportanceLevel;
  onChange: (checked: ImportanceLevel) => void;
  isDisabled?: boolean;
  children: ReactNode;
};

export const ControlImportanceLevelRadio = ({
  checked,
  onChange,
  isDisabled,
  children,
}: ControlImportanceLevelRadioProps) => {
  const { getRadioProps, isDisabled: _isDisabled } = useRadioGroup({
    value: checked,
    onChange: (value: string | number) => {
      onChange(value as ImportanceLevel);
    },
    isDisabled,
  });

  return (
    <RadioContext.Provider
      value={{
        getRadioProps,
        isDisabled: _isDisabled,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};

type ImportanceLevelRadioProps = UseRadioProps & {
  importanceLevel: ImportanceLevel;
};

const ImportanceLevelRadio = ({ importanceLevel, ...props }: ImportanceLevelRadioProps) => {
  const { getRadioProps, isDisabled } = useContext(RadioContext)!;
  const { state, getInputProps, getLabelProps, htmlProps } = useRadio({
    ...getRadioProps({ value: importanceLevel }),
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  const labelProps = getLabelProps();
  const inputProps = getInputProps();

  return (
    <chakra.label
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="all 120ms ease-in-out"
      border="none"
      borderRadius="6px"
      py="12px"
      px="14px"
      bg={state.isChecked ? 'rgba(77, 139, 255, 0.10)' : 'surface.primary'}
      color={state.isChecked ? '#4D8BFF' : 'text.mid-tone'}
      boxShadow={
        state.isChecked ? '0 0 0 1px #4D8BFF inset' : '0 0 0 1px rgba(228, 231, 236, 0.35) inset'
      }
      cursor={inputProps.disabled ? 'not-allowed' : 'pointer'}
      _hover={{
        transform: inputProps.disabled ? undefined : 'scale(1.05)',
      }}
      {...htmlProps}
    >
      <input {...inputProps} hidden />

      <Text
        variant={state.isChecked ? 'detail-sb' : 'detail'}
        color="inherit"
        transition="all 120ms ease-in-out"
        {...labelProps}
      >
        {translateImportanceLevel(importanceLevel)}
      </Text>
    </chakra.label>
  );
};

ControlImportanceLevelRadio.Radio = ImportanceLevelRadio;
