// React & Next
import { createContext, useContext } from 'react';
import type { AriaAttributes, AriaRole, ReactNode, CSSProperties, DOMAttributes } from 'react';

// 3rd
import { chakra } from '@chakra-ui/react';
import type { InputDOMAttributes, InputDOMProps, PropGetter } from '@chakra-ui/utils';

// App - Types
import type { RequirementSeverity } from '@/types/security-framework/requirement/requirement-severity';
import type { UseRadioProps } from '@/components/molecules/form';

// App - Other
import { Text } from '@/components/atoms/typography';
import { useRadio, useRadioGroup } from '@/components/molecules/form';
import {
  translateSeverity,
  translateSeverityToBgColorNEW,
  translateSeverityToColorNEW,
} from '@/components/translators/security-framework/severity';

const RadioContext = createContext<{
  getRadioProps: PropGetter<
    InputDOMProps &
      AriaAttributes &
      DOMAttributes<HTMLInputElement> & {
        id?: string | undefined;
        role?: AriaRole | undefined;
        tabIndex?: number | undefined;
        style?: CSSProperties | undefined;
      } & {
        isChecked?: boolean | undefined;
      },
    InputDOMAttributes
  >;
  isDisabled?: boolean;
} | null>(null);

type RequirementSeverityRadioProps = {
  checked: RequirementSeverity;
  onChange: (checked: RequirementSeverity) => void;
  isDisabled?: boolean;
  children: ReactNode;
};

export const RequirementSeverityRadio = ({
  checked,
  onChange,
  isDisabled,
  children,
}: RequirementSeverityRadioProps) => {
  const { getRadioProps, isDisabled: _isDisabled } = useRadioGroup({
    value: checked,
    onChange: (value: string | number) => {
      onChange(value as RequirementSeverity);
    },
    isDisabled,
  });

  return (
    <RadioContext.Provider
      value={{
        getRadioProps,
        isDisabled: _isDisabled,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};

type SeverityRadioProps = UseRadioProps & {
  severity: RequirementSeverity;
};

const SeverityRadio = ({ severity, ...props }: SeverityRadioProps) => {
  const { getRadioProps, isDisabled } = useContext(RadioContext)!;
  const { state, getInputProps, getLabelProps, htmlProps } = useRadio({
    ...getRadioProps({ value: severity }),
    ...(props ? { isDisabled, ...props } : { isDisabled }),
  });

  const labelProps = getLabelProps();
  const inputProps = getInputProps();

  return (
    <chakra.label
      display="flex"
      alignItems="center"
      justifyContent="center"
      transition="all 120ms ease-in-out"
      border="none"
      borderRadius="6px"
      py="12px"
      px="14px"
      bg={state.isChecked ? translateSeverityToBgColorNEW(severity) : 'surface.primary'}
      color={state.isChecked ? translateSeverityToColorNEW(severity) : 'text.mid-tone'}
      boxShadow={
        state.isChecked
          ? `0 0 0 1px ${translateSeverityToColorNEW(severity)} inset`
          : '0 0 0 1px rgba(228, 231, 236, 0.35) inset'
      }
      cursor={inputProps.disabled ? 'not-allowed' : 'pointer'}
      _hover={{
        transform: inputProps.disabled ? undefined : 'scale(1.05)',
      }}
      {...htmlProps}
    >
      <input {...inputProps} hidden />

      <Text
        variant={state.isChecked ? 'detail-sb' : 'detail'}
        color="inherit"
        transition="all 120ms ease-in-out"
        {...labelProps}
      >
        {translateSeverity(severity)}
      </Text>
    </chakra.label>
  );
};

RequirementSeverityRadio.Radio = SeverityRadio;
